import { Breadcrumb, Card, Flex, Typography } from 'antd'

const { Title } = Typography

const Note: React.FC = () => {
  return (
    <div>
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              title: '日志',
            },
            {
              title: '日志详情',
            },
          ]}
        />
        <Title
          level={4}
          style={{ margin: '5px 0 10px 0' }}
        >
          <Flex
            justify="flex-start"
            align="center"
            gap="small"
            vertical={false}
          >
            日志
          </Flex>
        </Title>
        <Card>Noto Todo...</Card>
      </Flex>
    </div>
  )
}

export default Note
